import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import {
    graphql,
    Link,
} from 'gatsby';
import Img from 'gatsby-image';
import moment from 'moment';

import {
    Button,
    Container,
    Divider,
    Grid,
    Header,
    Image,
    List,
    Message,
    Segment,
    Sticky,
    Visibility,
} from 'semantic-ui-react';
import Layout from '../components/layout';

import ArticleHeader from '../components/ArticleHeader';
import ContentParser from '../components/WordPress/ContentParser';
import Helmet from '../components/Helmet';

import WordPressPost from '../models/WordPressPost';
import BannerAd from '../images/banners/a-clear-path-160x600.jpg';
import AdvertiserDisclosurePopUp from '../components/Disclosures/AdvertiserPopUp';

const Avatar = styled(Image)`
    float: left;
    border: 1px solid white;
    height: 50px;
    width: 50px;
    margin-right: 10px
`;

const FeaturedImage = styled(Img)`
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
    max-width: 700px;
`;

const PostMeta = styled(Segment)`
    &&&&& {
        display: inline-block;
        width: 100%;
        padding-top: 0.25em;
        margin-bottom: 0.5em;
    }
`;
PostMeta.List = styled(List)`&&&&& {
    width: 100%;
    @media only screen and (max-width: 479px) {
        padding-top: 1em;
        font-size: 0.6em;
    }
}`;
PostMeta.List.Author = styled(List.Item)`
    float: left;
    clear: left;
`;
PostMeta.List.Dates = styled(List.Item)`
    float: right;
    padding-top: 0;
    text-align: right;
    font-size: 0.85em;
`;

PostMeta.AdvertiserDisclosurePopUp = styled(Segment)`
    &&&&& {
       border: 0 solid transparent;
       box-shadow: none;
       padding: 0;
       margin-bottom: 0.5em;
    }
`;

const SidebarLeft = styled(Grid.Column)`
    &&&&& {
        padding-top: 6em;
        margin: 0;
    }
`;

const SidebarRight = styled(Grid.Column)`
    &&&&& {
        padding-top: 6em;
        padding-left: 0;
        margin: 0 0 0 1rem;
    }
`;

const SidebarCta = styled(Segment)`
    &&&&& {
        border: none;
        border-top: 1em #084E8A solid;
        svg {
            fill: #084E8A;
            width: 4em;
            height: auto;
        }
    }
`;

const ArticleCta = styled(SidebarCta)`
    &&&&& {
        svg {
            width: 6em;
        }
        .header {
            font-size: xx-large;
        }
    }
`;

const SidebarEducationCenter = styled(Segment)`
    &&&&& {
        padding-left: 0;
        padding-right: 0;
    }
`;

export default class PostDefault extends React.Component {
    state = {
        showQSBanner: false,
    };

    static propTypes = {
        data: PropTypes.shape({
            article: PropTypes.shape({
                title: PropTypes.string,
            }).isRequired,
        }).isRequired,
    };

    handleStickyContextRef = contextRef => this.setState({ contextRef });

    showQSBanner() {
        if (typeof document !== 'object') {
            return;
        }

        const articleMain = document.getElementsByClassName('article-main')[0];
        const sideBarRight = document.getElementsByClassName('sidebar-right')[0];
        const articleMainBottom = articleMain.getBoundingClientRect().bottom;
        const sideBarRightBottom = sideBarRight.getBoundingClientRect().bottom;

        if (sideBarRightBottom + 600 > articleMainBottom) {
            return;
        }

        this.setState({ showQSBanner: true });
    }

    render() {
        const { contextRef, showQSBanner } = this.state;
        const {
            data,
        } = this.props;

        const article = new WordPressPost(data.article);
        console.log(article);
        const aspectRatio = 16 / 12;

        const featuredImageHuge = {
            width: 1000,
            height: Math.round(1000 * (aspectRatio ** -1)),
            url: article.featured_image.sourceImage.url
                .replace(/\d+x\d+/gm, `1000x${Math.round(1000 * (aspectRatio ** -1))}`),
        };

        const featuredImage = {
            aspectRatio,
            presentationWidth: featuredImageHuge.width,
            presentationHeight: featuredImageHuge.height,
            sizes: `(max-width: ${featuredImageHuge.width}px) 100vw, ${featuredImageHuge.width}px`,
            src: featuredImageHuge.url,
            srcSet: `${article.featured_image.smallImage.url} ${article.featured_image.smallImage.width}w,
                ${article.featured_image.mediumImage.url} ${article.featured_image.mediumImage.width}w,
                ${article.featured_image.largeImage.url} ${article.featured_image.largeImage.width}w,
                ${featuredImageHuge.url} ${featuredImageHuge.width}w`,
        };

        return (
            <Layout>
                <Helmet
                    canonicalUrl={article.slug}
                    title={article.titleSeo}
                    description={article.excerpt}
                    image={article.ogImage}
                    robots={article.robots}
                    isArticle
                    datePublished={article.date}
                    dateModified={article.modified}
                    author={{
                        name: article.author.name,
                        image: article.author.photo,
                    }}
                />
                
                <FeaturedImage fluid={featuredImage} />
                <Container>
                    <div ref={this.handleStickyContextRef}>
                        <Grid columns="equal">
                            <SidebarLeft only="large screen">
                                <Sticky offset={20} context={contextRef}>
                                    <List>
                                        <List.Item><Button aria-label="twitter" color="twitter" icon="twitter" size="huge" href="https://twitter.com/officialdc_com" /></List.Item>
                                        <List.Item><Button aria-label="facebook" color="facebook" icon="facebook f" size="huge" href="https://www.facebook.com/debtconsolidationofficial/" /></List.Item>
                                    </List>
                                </Sticky>
                            </SidebarLeft>
                            <Grid.Column as="article" className="article-main">
                                <PostMeta basic clearing as="header">
                                    <PostMeta.AdvertiserDisclosurePopUp>
                                        <AdvertiserDisclosurePopUp position='bottom left' />
                                    </PostMeta.AdvertiserDisclosurePopUp>
                                    <Avatar circular>
                                        <Image fluid src={article.author.photo} />
                                    </Avatar>
                                    <PostMeta.List size="small">
                                        <PostMeta.List.Author>
                                            <List.Content>
                                                <List.Header>
                                                    By <a href="/about/">DebtConsolidation Team</a>
                                                </List.Header>
                                                <List.Description>
                                                    DebtConsolidation.com
                                                </List.Description>
                                            </List.Content>
                                        </PostMeta.List.Author>
                                        <PostMeta.List.Dates>
                                            <List.Content>
                                                <List.Description>
                                                    {`Published: ${moment(article.date).format('ll')}`}
                                                    <br />
                                                    {`Updated: ${moment(article.modified).format('ll')}`}
                                                </List.Description>
                                            </List.Content>
                                        </PostMeta.List.Dates>
                                    </PostMeta.List>
                                </PostMeta>
                                <ArticleHeader
                                    title={article.title}
                                    categories={article.categories}
                                    readTimeMinutes={article.read_time_minutes}
                                />
                                <ContentParser
                                    slug={article.slug}
                                    content={article.content}
                                    sections={[]}
                                    parseShortcodes
                                    cta={<></>}
                                    relatedPosts={article.related_posts}
                                />
                            </Grid.Column>

                            <SidebarRight only="computer">

                                {/* <div className="sidebar-right">
                                    <Sticky context={contextRef}>
                                        <Visibility as="div" onTopVisible={this.showQSBanner.bind(this)} centered="true">
                                            <Link to="/">
                                                <Image src={BannerAd} />
                                            </Link>
                                        </Visibility>
                                    </Sticky>
                                </div> */}
                            </SidebarRight>

                        </Grid>
                    </div>
                </Container>
                <Container text>
                    <Segment basic textAlign="center">
                        <List horizontal>
                            <List.Item><Button aria-label="twitter" color="twitter" icon="twitter" size="huge" href="https://twitter.com/officialdc_com" /></List.Item>
                            <List.Item><Button aria-label="facebook" color="facebook" icon="facebook f" size="huge" href="https://www.facebook.com/debtconsolidationofficial/" /></List.Item>
                        </List>
                    </Segment>
                </Container>
                <Divider hidden />
                {/* <Container>
                    <Message className="cta">
                        <Message.Header>Financial Freedom is&nbsp;Possible!</Message.Header>
                        <div>
                            <Button as="a" secondary size="medium" href="/#get-started">Get Started Now</Button>
                        </div>
                        <small>Fast &bull; Free &bull; No Obligation</small>
                    </Message>
                </Container> */}
                <Divider hidden />
                <Container text className="wide">
                    <Segment basic>
                        <Header>Learn About Debt</Header>
                        <List relaxed className="featured-articles footer">
                            <List.Item><a href="/6-steps-out-of-debt/">The Simple 6-Step Process To Getting Out of Debt</a></List.Item>
                            <List.Item><a href="/stop-debt-collector-calls/">How To Permanently Stop Debt Collectors From Calling You</a></List.Item>
                            <List.Item><a href="/understanding-debt-consolidation/">Understanding Debt Consolidation: What It Does and How It Can Help</a></List.Item>
                            <List.Item><a href="/how-to-get-out-of-debt/">How to Get Out of Debt</a></List.Item>
                        </List>
                    </Segment>
                </Container>
            </Layout>
        );
    }
}

export const query = graphql`query PostDefaultQuery($slug: String!) {
    bestImage: file (
        relativePath: {
            eq: "articles/best.jpg"
        }
    ) {
        id
        relativePath
        childImageSharp {
            id
            fluid(
                maxWidth: 130
                cropFocus: ENTROPY
            ) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                originalImg
                originalName
                presentationWidth
                presentationHeight
            }
        }
    }
    article: wordpressPost(
        slug: {
            eq: $slug
        }
    ) {
        id
        title
        slug
        date
        modified
        excerpt
        template
        discoverable
        author {
            name
            description
            slug
            url
        }
        authorship {
            name
            slug
            wordpress_id
        }
        featured_image {
            smallImage {
                url
                width
                height
            }
            mediumImage {
                url
                width
                height
            }
            largeImage {
                url
                width
                height
            }
            source_url
            sourceImage {
                url
                width
                height
            }
        }
        categories {
            id
            name
            slug
        }
        acf {
            seo_title
            robots
        }
        read_time_minutes
        content
    }
}`;
