import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class ThirdPartyContent extends Component {
    componentDidMount() {
        let { js } = this.props;

        // if a string is provided, convert to array
        if (typeof js === 'string') {
            js = js.replace(/\s/g, '')
                .split(',');
        }

        // load the scripts
        if (js && js.length > 0) {
            this.loadScripts(js);
        }
    }

    loadScripts(scripts) {
        if (!scripts || scripts.length === 0) return;

        let src;
        let script;
        let pendingScripts = [];
        const firstScript = document.scripts[0];

        const stateChange = (function () {
            let pendingScript;

            while (pendingScripts[0] && pendingScripts[0].readyState === 'loaded') {
                pendingScript = pendingScripts.shift();
                pendingScript.onreadystatechange = null;
                firstScript.parentNode.insertBefore(pendingScript, firstScript);
            }
        });

        while (src = scripts.shift()) {
            /* MODERN BROWSERS */
            if (firstScript && 'async' in firstScript) {
                script = document.createElement('script');
                script.async = false;
                script.src = src;
                document.head.appendChild(script);
                /* IE<10 */
            } else if (firstScript && firstScript.readyState) {
                script = document.createElement('script');
                pendingScripts.push(script);
                script.onreadystatechange = stateChange;
                script.src = src;
                /* FAILSAFE */
            } else {
                document.write('<script src="' + src + '" defer></' + 'script>');
            }
        }
    }

    render() {
        let { css } = this.props;

        if (typeof css === 'string') {
            css = css.replace(/\s/g, '')
                .split(',');
        }

        // build list of stylesheets
        if (css && css.length > 0) {
            css = css.map((file) => {
                return {
                    rel: 'stylesheet',
                    href: file,
                };
            });
        }

        return (
            <div>
                <Helmet link={css}/>
                {this.props.children}
            </div>
        );
    }
}

ThirdPartyContent.propTypes = {
    js: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]),
    css: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]),
    children: PropTypes.node.isRequired,
};

export default ThirdPartyContent;
