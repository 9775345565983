import React from 'react';
import ReactDOM from "react-dom";
import PropTypes from 'prop-types';


import {
    Visibility,
} from 'semantic-ui-react';

const CARDGURU_AFFILIATE_ID = '640763';
const BANNER_MATRIX = {
    'cash-back': {
        '728x90': ';;n=203;c=1620142/485404/223057;s=7273;d=14;',
        '160x600': ';;n=203;c=1620141/485404/223057;s=7273;d=7;',
        '468x60': ';;n=203;c=1652942/485404/223057;s=7273;d=0;',
        '300x250': ';;n=203;c=1620139/485404/223057;s=7273;d=9;',
    },
    'travel': {
        '728x90': ';;n=203;c=1620130/485404/223057;s=7273;d=14;',
        '160x600': ';;n=203;c=1651716/485404/223057;s=7273;d=7;',
        '468x60': ';;n=203;c=1652941/485404/223057;s=7273;d=0;',
        '300x250': ';;n=203;c=1620003/485404/223057;s=7273;d=9;',
    },
    'bad-credit': {
        '728x90': ';;n=203;c=1650747/485404/223057;s=7273;d=14;',
        '160x600': ';;n=203;c=1652953/485404/223057;s=7273;d=7;',
        '468x60': ';;n=203;c=1652944/485404/223057;s=7273;d=0;',
        '300x250': ';;n=203;c=1650745/485404/223057;s=7273;d=9;',
    },
    'balance-transfer': {
        '728x90': ';;n=203;c=1648724/485404/223057;s=7273;d=14;',
        '160x600': ';;n=203;c=1621823/485404/223057;s=7273;d=7;',
        '468x60': ';;n=203;c=1652944/485404/223057;s=7273;d=0;',
        '300x250': ';;n=203;c=1651800/485404/223057;s=7273;d=9;',
    },
};

const renderTable = ((id, slot) => {
    const aScript = document.createElement('script');
    aScript.type = 'text/javascript';
    aScript.src = 'https://www.googletagservices.com/tag/js/gpt.js';
    document.head.appendChild(aScript);

    const gptadslots = [];
    const googletag = window.googletag || { cmd: [] };

    googletag.cmd.push(() => {
        const mapping1 = googletag.sizeMapping()
            .addSize([1224, 768], [[720, 360]])
            .addSize([0, 0], [[320, 480]])
            .build();
        gptadslots.push(googletag.defineSlot('/5055/CreditCards/categorywidgets', [[720,360],[320,480]], `div-gpt-ad-${id}-${slot}`)
            .defineSizeMapping(mapping1)
            .addService(googletag.pubads()));
        googletag.pubads().enableSingleRequest();
        googletag.pubads().setTargeting('kw', [`widget${slot}`]).setTargeting('src', [CARDGURU_AFFILIATE_ID]).setTargeting('var2',['widget']);
        googletag.pubads().collapseEmptyDivs(); googletag.pubads().setForceSafeFrame(false);
        googletag.enableServices();
    });

    googletag.cmd.push(() => { googletag.display(`div-gpt-ad-${id}-${slot}`); });
    window.googletag = googletag;
});

const TABLE_MATRIX = {
    'cash-back': {
        id: '6598859',
        slot: '5'
    },
    'travel': {
        id: '6598859',
        slot: '1'
    },
    'balance-transfer': {
        id: '6598859',
        slot: '4'
    },
    'bad-credit': {
        id: '6598859',
        slot: '4'
    }
};

export default class BannerAd extends React.Component {
    static propTypes = {
        desktopSize: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        className: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        style: PropTypes.object
    };

    static defaultProps = {
        desktopSize: '728x90',
        category: 'dynamic',
        className: 'banner',
        type: 'banner'
    };

    constructor(props) {
        super(props);

        const desktopSize = props.desktopSize.replace('×', 'x');
        const dimensions = desktopSize.split('x');

        this.state = {
            id: `banner_${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10)}`,
            showBanner: false,
            size: desktopSize,
            category: (props.category === 'dynamic' ? 'cash-back' : props.category),
            width: dimensions[0],
            height: dimensions[1],
            parentWidth: null,
            parentHeight: null,
            type: props.type
        }
    }

    setDynamicCategory() {
        let { category } = this.state;
        const title = document.title.toLowerCase();

        if (title.includes('cash back')) {
            category = 'cash-back';
        } else if (title.includes('bad credit')) {
            category = 'bad-credit';
        } else if (title.includes('travel')) {
            category = 'travel';
        } else if (title.includes('balance transfer')) {
            category = 'balance-transfer';
        } else {
            category = 'cash-back'
        }

        this.setState({category: category});
    }

    componentDidMount() {
        if (typeof document !== 'object' || !this.node) {
            return;
        }

        const { category } = this.props;
        const { width, size, id } = this.state;
        const domNode = ReactDOM.findDOMNode(this);
        const parent = domNode.parentNode;

        if (category === 'dynamic') {
            this.setDynamicCategory();
        }

        const clickListener = window.addEventListener('blur', () => {

            if (document.activeElement === document.getElementById(id)) {
                console.log('bannerClick');
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'bannerClick'
                });
            }
        });

        // downsize if necessary
        if (parent.offsetWidth >= width) {
            return;
        }

        if (size === '728x90') {
            if (parent.offsetWidth >= 468) {
                this.setState({
                    size: '468x60',
                    width: 468,
                    height: 60
                });
                return;
            }

            this.setState({
                size: '300x250',
                width: 300,
                height: 250
            });
            return;
        }
    }

    showBanner() {
        const { desktopSize } = this.props;
        this.setState({ showBanner: true });
        const category = this.state.category;
        const table = TABLE_MATRIX[category];

        if (desktopSize === 'table') {
            setTimeout(() => { renderTable(table.id,  table.slot); }, 300);
        }
    }

    getBannerCode() {
        const { category, size } = this.state;

        if (!BANNER_MATRIX[category]) {
            return BANNER_MATRIX['cash-back'][size]
        }

        return BANNER_MATRIX[category][size];
    }

    render() {
        const { className, style } = this.props;
        const { showBanner, category, size } = this.state;
        let banner = this.banner();

        if (size === 'table') {
            banner = this.table(category);
        }

        return (
            <Visibility as="div" style={style} onTopVisible={this.showBanner.bind(this)} centered="true" className={`banner ${className}`} ref={(node) => { this.node = node; }}>
                {showBanner ? banner : null}
            </Visibility>
        );
    }

    banner() {
        const bannerCode = this.getBannerCode();
        const { width, height, id } = this.state;

        return (<iframe id={id} src={`https://o1.qnsr.com/sjsc/o1/ff2.html?;p=src%3D${CARDGURU_AFFILIATE_ID}${bannerCode}w=${width};h=${height}`} frameBorder="0" marginHeight="0" marginWidth="0" scrolling="no" width={width} height={height}></iframe>);
    }

    table(category) {
        return (<div id={`div-gpt-ad-${TABLE_MATRIX[category].id}-${TABLE_MATRIX[category].slot}`} />);
    }
}
