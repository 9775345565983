export default class Model {
    /**
     * @param {{}} plainObject A plain object that needs to be coerced into a CreditCard
     */
    constructor(plainObject) {
        if (plainObject === null) {
            return;
        }
        Object.keys(plainObject).forEach((key) => { this[key] = plainObject[key]; });
    }

    /**
     * Save the bytes!
     * @returns {{} & Model}
     */
    toJSON() {
        const jsonObj = Object.assign({}, this);
        const proto = Object.getPrototypeOf(this);

        for (const key of Object.getOwnPropertyNames(proto)) {
            const desc = Object.getOwnPropertyDescriptor(proto, key);
            const hasGetter = desc && typeof desc.get === 'function';
            if (hasGetter) {
                jsonObj[key] = this[key];
            }
        }

        return jsonObj;
    }
}
