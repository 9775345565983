import PropTypes from 'prop-types';
import React from 'react';

export default class FormulaItem extends React.Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
    }

    render() {
        let { operator } = this.props;
        // rewrite as nicer operator characters
        if (operator === 'x' || operator === '*') {
            // rewrite x, * as the multiplication character ×
            operator = '×';
        } else if (operator === '/') {
            // rewrite / as the division character ÷
            operator = '÷';
        }

        return (
            <li className="formula__item">
                { operator ? (
                    <div className={'formula__operator' + (operator === '=' ? ' formula__operator--result' : '')}>{ operator }</div>
                ) : ''}
                <div className="formula__value">{ this.props.children }</div>
            </li>
        );
    }
}

FormulaItem.propTypes = {
    children: PropTypes.node.isRequired,
    operator: PropTypes.oneOf(['+', '-', '=', '×', 'x', '÷', '*', '/'])
};

FormulaItem.defaultProps = {
    operator: null,
};
