import JsxParser from 'react-jsx-parser';
import PropTypes from 'prop-types';
import React from 'react';
import {
    Accordion,
    Button,
    Card,
    Embed,
    Grid,
    Header,
    Icon,
    Image,
    List,
    Message as SemanticMessage,
    Modal,
    Segment,
} from 'semantic-ui-react';
import styled from 'styled-components';

import ShortcodeParser from './ShortcodeParser';
import StepList from '../StepList';
import LazyLoadImage from '../LazyLoadImage';
import ThirdPartyContent from '../ThirdPartyContent';
import BannerAd from '../BannerAd';
import Formula from '../Formula';
import FormulaItem from '../FormulaItem';
import EvenFinancialWidget from '../EvenFinancialWidget';

const Message = styled(SemanticMessage)`
    text-align: ${({ centered }) => (centered ? 'center' : 'inherit')};
`;

/*
 * Parse and process all shortcodes
 *
 *
 *
 * Dear developer looking at this file,
 *     Please keep the shortcodes in alphabetical order
 *     as best you can so other developers can find things
 *     more easily when they need to make changes or add
 *     new shortcodes to the Content Parser.
 *                          Sincerely,
 *                            Ryan
 *
 *
 *
 */
const shortcodes = new ShortcodeParser()
    // callout
    .addTag('callout', () => '<></>')

    // Checklist
    .addTag('Checklist', shortcode => `
        <List relaxed>
            ${shortcode.content}
        </List>
    `)
    .addTag('ChecklistItem', shortcode => `
        <List.Item>
            <Icon name="check" color="green" />
            <List.Content>
                ${shortcode.content}
            </List.Content>
        </List.Item>
    `)

    // Disrupt
    .addTag('Disrupt', shortcode => `
        <div className="disrupt ${shortcode.get('color')}">
            ${shortcode.content}
        </div>
    `)

    // Expander
    .addTag('Expander', shortcode => `
        <Accordion exclusive={false} fluid panels={[${shortcode.content}]} />
    `)
    .addTag('ExpanderItem', shortcode => `{
        key: "${shortcode.get('title').toLowerCase().replace(/[^a-z]/gm, '-')}",
        title: {
            content: (<strong>${shortcode.get('title')}</strong>)
        },
        content: {
            content: (<div>${shortcode.content}</div>)
        }
    },`)

    // Formula
    .addTag('Formula', shortcode => `
         <Formula
            ${shortcode.getProp('heading')}
         >
            ${shortcode.content}
         </Formula>
    `)

    .addTag('FormulaItem', shortcode => `
         <FormulaItem
            ${shortcode.getProp('operator')}
        >
            ${shortcode.content}
        </FormulaItem>
    `)

    // Flash
    .addTag('Flash', (shortcode) => {
        const map = {
            alert: 'warning',
            error: 'error',
            info: 'default',
            notice: 'info',
            success: 'positive',
        };
        return `
            <Message icon floating ${map[shortcode.get('level')] || shortcode.get('level')}>
                ${shortcode.content}
            </Message>
        `;
    })

    // FlashOffer
    .addTag('FlashOffer', shortcode => `
        <Message icon positive>
            <Icon name='credit card' />
            <Message.Content>
                Looking for this card? <a href="${shortcode.get('link')}">Apply now</a>
            </Message.Content>
        </Message>
    `)

    // GraphicList
    .addTag('GraphicList', shortcode => `
        <List relaxed>
            ${shortcode.content}
        </List>
    `)
    .addTag('GraphicListItem', shortcode => `
        <List.Item>
            <Image src="${shortcode.get('image')}" style={{ maxWidth: '24%' }} />
            <List.Content style={{ maxWidth: '74%' }}>
                ${shortcode.content}
            </List.Content>
        </List.Item>
    `)

    // Image
    .addTag('Image', shortcode => `
        <Card centered fluid as="figure">
            <Image
                fluid
                ${shortcode.getProp('src')} 
                ${shortcode.getProp('alt')} 
                ${shortcode.getProp('fullwidth')}
             />
            <Card.Content as="figcaption">
                <Card.Description>
                    ${shortcode.content}
                </Card.Description>  
            </Card.Content>  
        </Card>
    `)

    // Infographic
    .addTag('Infographic', (shortcode) => {
        const preview = (shortcode.get('image') !== shortcode.get('preview'))
            ? shortcode.get('preview')
            : shortcode.get('preview').replace('media/', 'media/resize/200x200/');
        return `
            <Segment basic textAlign="center">
                <Header>
                    ${shortcode.get('title')}
                    <Header.Subheader>Click the preview below for the full version</Header.Subheader>
                </Header>
                <Modal trigger={<Image centered src="${preview}" style={{ maxWidth: '200px' }} />} closeIcon>
                    <Modal.Header>${shortcode.get('title')}</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Image src="${shortcode.get('image')}" wrapped />
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
            </Segment>
        `;
    })

    // ProCon
    .addTag('ProCon', shortcode => `
        <Grid columns={2} divided>
            ${shortcode.content}
        </Grid>
    `)
    .addTag('Pros', shortcode => `
        <Grid.Column>
            <Segment basic color="green">
                <Header color="green">Pros</Header>
                <List relaxed>
                    ${shortcode.content}
                </List>
            </Segment>
        </Grid.Column>
    `)
    .addTag('Pro', shortcode => `
        <List.Item>
            <Icon name="check" color="green" />
            <List.Content>${shortcode.content}</List.Content>
        </List.Item>
    `)
    .addTag('Cons', shortcode => `
        <Grid.Column>
            <Segment basic color="red">
                <Header color="red">Cons</Header>
                <List relaxed>
                    ${shortcode.content}
                </List>
            </Segment>
        </Grid.Column>
    `)
    .addTag('Con', shortcode => `
        <List.Item>
            <Icon name="times" color="red" />
            <List.Content>${shortcode.content}</List.Content>
        </List.Item>
    `)

    // PullQuote
    .addTag('PullQuote', shortcode => `
        <blockquote>
            <p>${shortcode.content}</p>
        </blockquote>
    `)

    // StepList
    .addTag('StepList', shortcode => `
        <StepList>
            ${shortcode.content}
        </StepList>
    `)
    .addTag('StepListItem', shortcode => `
        <StepList.Item ${shortcode.getProp('heading')}>
            ${shortcode.content}
        </StepList.Item>
    `)
    .addTag('BannerAd', shortcode => `
        <BannerAd desktopSize="${shortcode.get('desktopsize')}" />
    `)

    // TableOfContents
    // TODO: Make the TableOfContents component actual do something
    .addTag('TableOfContents', () => '<></>')

    // ThirdPartyContent
    .addTag('ThirdPartyContent', shortcode => `
        <ThirdPartyContent
            ${shortcode.getProp('js')}
            ${shortcode.getProp('css')}
        >
            ${shortcode.content}
        </ThirdPartyContent>
    `)

    // Waistband
    .addTag('Waistband', shortcode => `
        <Message ${shortcode.getProp('color')} ${shortcode.getProp('centered')}>
            ${shortcode.content}
        </Message>
    `)

    // YouTube
    .addTag('YouTube', shortcode => `
        <Embed id="${shortcode.get('video')}" source="youtube" aspectRatio="16:9" />
    `)


export default class ContentParser extends React.Component {
    static propTypes = {
        content: PropTypes.string.isRequired,
        sections: PropTypes.array,
        parseShortcodes: PropTypes.bool,
        cards: PropTypes.arrayOf(PropTypes.shape({
            identifier: PropTypes.string,
        })),
        cta: PropTypes.node.isRequired,
        terms: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string.isRequired,
            short: PropTypes.string.isRequired,
            long: PropTypes.string.isRequired,
        })),
        relatedPosts: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string.isRequired,
        })),
    };

    static defaultProps = {
        cards: [],
        parseShortcodes: false,
        sections: [],
        terms: [],
        relatedPosts: [],
    };

    onError(e) {
        console.error(this.jsx);
        const ex = `ContentParser Error Rendering JSX: ${e.message}`;
        throw new Error(ex);
    }

    parse(jsx) {
        return (
            <JsxParser
                components={{
                    Accordion,
                    BannerAd,
                    Button,
                    Embed,
                    EvenFinancialWidget,
                    Formula,
                    FormulaItem,
                    Grid,
                    Header,
                    Icon,
                    Image,
                    LazyLoadImage,
                    List,
                    Message,
                    Modal,
                    Segment,
                    StepList,
                    ThirdPartyContent,
                }}
                jsx={jsx}
                onError={this.onError}
                showWarnings
                renderInWrapper={false}
            />
        );
    }

    render() {
        const {
            cards,
            content,
            cta,
            parseShortcodes,
            sections,
            slug,
            terms,
            relatedPosts,
        } = this.props;

        if (!content) {
            return null;
        }

        let contentParsed = content;

        /*
         * Fixes a whitespace rendering issue (primarily with tables)
         * This is fixed in the newer version of react-jsx-parser (which requires React 16.X)
         * See: https://github.com/TroyAlford/react-jsx-parser/issues/52
         */
        // contentParsed = content.replace(/\>(\s|\n|\r|\t)*?\</g, '><');

        /*
         * Parse shortcodes, if desired
         * This option exists because we don't want to parse shortcodes in the content style guide
         */
        if (parseShortcodes) {
            shortcodes
                .replaceTag('Card', (shortcode) => {
                    const identifier = shortcode.get('identifier') || shortcode.get('ai_id');
                    const card = cards.find(c => c.identifier === identifier);
                    if (!card) {
                        return '<></>';
                    }
                    switch (shortcode.get('type')) {
                        case 'mini':
                        case 'detailed': // temporarily fall through to mini
                        case 'simple': // temporarily fall through to mini
                            return `
                                <CreditCardCtaMini
                                    href="/card-details/${card.identifier}/"
                                    image={${JSON.stringify(card.image)}}
                                    name="${card.name}"
                                    rating={${card.aggregateRating && card.aggregateRating.value}}
                                />
                            `;
                        case 'overview':
                        case 'editorial': // temporarily fall through to overview
                        case 'preview': // temporarily fall through to overview
                            return `
                                <CreditCardCtaOverview
                                    href="/card-details/${card.identifier}/"
                                    card={${JSON.stringify(card)}}
                                />
                            `;
                        case 'link':
                            return `
                                <CreditCardLink
                                    href="${card.url}/"
                                    card={${JSON.stringify(card)}}
                                />
                            `;
                        default:
                            return null;
                    }
                })

                .replaceTag('cc', shortcode => new CreditCardSyntaxParser(cards, shortcode.getValue('id')).field(shortcode.getValue('field') || shortcode.getValue('prop')))

                // Glossary
                .replaceTag('Glossary', (shortcode) => {
                    const term = terms.find(term => term.slug === shortcode.get('slug'));
                    if (term && term.slug) {
                        return `
                            <List as="dl">
                                <List.Item>
                                    <List.Content>
                                        <List.Header as="dt">${term.title}</List.Header>
                                        <List.Description as="dd">${term.long}</List.Description>
                                    </List.Content>
                                </List.Item>
                            </List>
                        `;
                    }
                    return '<></>';
                })

                .replaceTag('YouMayAlsoEnjoy', () => {
                    let relatedPostsMarkup = '<ul>';
                    relatedPosts.slice(0, 2).forEach((post) => {
                        relatedPostsMarkup += `<li><a href="${post.slug}">${post.title}</a></li>`;
                    });
                    relatedPostsMarkup += '<li><a href="/learn/how-to-compare/">How to Compare Credit Cards</a></li></ul>';
                    return `
                        <div class="you-might-also-enjoy">
                            <strong>You May Also Enjoy</strong>
                            ${relatedPostsMarkup}
                        </div>
                    `;
                })

                .replaceTag('EvenFinancialWidget', shortcode => `
                    <EvenFinancialWidget slug={${slug}} ${shortcode.getProp('url')}/>
                `)

            contentParsed = shortcodes.parse(contentParsed);
        }

        contentParsed = contentParsed
            .replace(
                /https:\/\/wordpressproxy\.speedwellholdings\.com\/\w+\.com\/dev\/wp-content\/uploads/g,
                '/media'
            );
        return this.parse(contentParsed);
    }
}
