import React from 'react';
import StepListItem from './StepListItem';
import styled from 'styled-components';

export const List = styled.ol`
    list-style-type: none;
    counter-reset: step;
    padding-left: 0;
    clear: both;
`;

export default class StepList extends React.PureComponent {
    static Item = StepListItem;

    render() {
        const {
            children,
        } = this.props;
        return <List>{ children }</List>;
    }
}
