import * as _ from 'lodash';

const wp = {};

wp.shortcode = {

    /**
     * Find the next matching shortcode
     *
     * Given a shortcode `tag`, a block of `text`, and an optional starting
     * `index`, returns the next matching shortcode or `undefined`.
     *
     * Shortcodes are formatted as an object that contains the match
     * `content`, the matching `index`, and the parsed `shortcode` object.
     *
     * @param tag
     * @param text
     * @param index
     * @return {*}
     */
    next(tag, text, index) {
        const re = wp.shortcode.regexp(tag);
        let match;
        let result;

        re.lastIndex = index || 0;
        match = re.exec(text);

        if (!match) {
            return;
        }

        // If we matched an escaped shortcode, try again.
        if (match[1] === '[' && match[7] === ']') {
            return wp.shortcode.next(tag, text, re.lastIndex);
        }

        result = {
            index: match.index,
            content: match[0],
            shortcode: wp.shortcode.fromMatch(match),
        };

        // If we matched a leading `[`, strip it from the match
        // and increment the index accordingly.
        if (match[1]) {
            result.content = result.content.slice(1);
            result.index++;
        }

        // If we matched a trailing `]`, strip it from the match.
        if (match[7]) {
            result.content = result.content.slice(0, -1);
        }

        return result;
    },

    /**
     * Replace matching shortcodes in a block of text
     *
     * Accepts a shortcode `tag`, content `text` to scan, and a `callback`
     * to process the shortcode matches and return a replacement string.
     * Returns the `text` with all shortcodes replaced.
     *
     * ShortcodeParser matches are objects that contain the shortcode `tag`,
     * a shortcode `attrs` object, the `content` between shortcode tags,
     * and a boolean flag to indicate if the match was a `single` tag.
     *
     * @param tag
     * @param text
     * @param callback
     * @return {*|string}
     */
    replace(tag, text, callback) {
        return text.replace(wp.shortcode.regexp(tag), function (match, left, tag, attrs, slash, content, closing, right) {
            // If both extra brackets exist, the shortcode has been
            // properly escaped.
            if (left === '[' && right === ']') {
                return match;
            }

            // Create the match object and pass it through the callback.
            const result = callback(wp.shortcode.fromMatch(arguments));

            // Make sure to return any of the extra brackets if they
            // weren't used to escape the shortcode.
            return result ? left + result + right : match;
        });
    },

    /**
     * Generate a string from shortcode parameters
     *
     * Creates a `wp.shortcode` instance and returns a string.
     *
     * Accepts the same `options` as the `wp.shortcode()` constructor,
     * containing a `tag` string, a string or object of `attrs`, a boolean
     * indicating whether to format the shortcode using a `single` tag, and a
     * `content` string.
     *
     * @param options
     * @return {*}
     */
    string(options) {
        return new ShortcodeParser(options).string();
    },

    /**
     * Generate a RegExp to identify a shortcode
     *
     * The base regex is functionally equivalent to the one found in
     * `get_shortcode_regex()` in `wp-includes/shortcodes.php`.
     *
     * Capture groups:
     * 1. An extra `[` to allow for escaping shortcodes with double `[[]]`
     * 2. The shortcode name
     * 3. The shortcode argument list
     * 4. The self closing `/`
     * 5. The content of a shortcode when it wraps some content.
     * 6. The closing tag.
     * 7. An extra `]` to allow for escaping shortcodes with double `[[]]`
     *
     * @param tag
     * @return {RegExp}
     */

    regexp: _.memoize(tag => new RegExp(`\\[(\\[?)(${tag})(?![\\w-])([^\\]\\/]*(?:\\/(?!\\])[^\\]\\/]*)*?)(?:(\\/)\\]|\\](?:([^\\[]*(?:(?!(?:\\[\\2(?:\\s|\\])))\\[(?!\\/\\2\\])[^\\[]*)*)(\\[\\/\\2\\]))?)(\\]?)`, 'gi')),
    regexp: _.memoize(tag => new RegExp(`\\[(\\[?)(${tag})(?![\\w-])([^\\]\\/]*(?:\\/(?!\\])[^\\]\\/]*)*?)(?:(\\/)\\]|\\](?:([^\\[]*(?:(?!(?:\\[\\2(?:\\s|\\])))\\[(?!\\/\\2\\])[^\\[]*)*)(\\[\\/\\2\\]))?)(\\]?)`, 'gi')),

    /**
     * Parse shortcode attributes
     *
     * Shortcodes accept many types of attributes. These can chiefly be
     * divided into named and numeric attributes:
     *
     * Named attributes are assigned on a key/value basis, while numeric
     * attributes are treated as an array.
     *
     * Named attributes can be formatted as either `name="value"`,
     * `name='value'`, or `name=value`. Numeric attributes can be formatted
     * as `"value"` or just `value`.
     *
     * @param text
     * @return {{named: {}, numeric: Array}}
     */
    attrs: _.memoize((text) => {
        const named = {};
        const numeric = [];
        let pattern;
        let match;

        // This regular expression is reused from `shortcode_parse_atts()`
        // in `wp-includes/shortcodes.php`.
        //
        // Capture groups:
        //
        // 1. An attribute name, that corresponds to...
        // 2. a value in double quotes.
        // 3. An attribute name, that corresponds to...
        // 4. a value in single quotes.
        // 5. An attribute name, that corresponds to...
        // 6. an unquoted value.
        // 7. A numeric attribute in double quotes.
        // 8. An unquoted numeric attribute.
        pattern = /(\w+)\s*=\s*"([^"]*)"(?:\s|$)|(\w+)\s*=\s*\'([^\']*)\'(?:\s|$)|(\w+)\s*=\s*([^\s\'"]+)(?:\s|$)|"([^"]*)"(?:\s|$)|(\S+)(?:\s|$)/g;

        // Map zero-width spaces to actual spaces.
        text = text.replace(/[\u00a0\u200b]/g, ' ');

        // Replace smart quotes
        text = text.replace(/”/g, '"').replace(/“/g, '"').replace(/&#8221;/g, '"').replace(/″/g, '"');

        // Match and normalize attributes.
        while ((match = pattern.exec(text))) {
            if (match[1]) {
                named[match[1].toLowerCase()] = (typeof match[2] === 'string') ? match[2].replace(/&#8221;/g, '') : match[2];
            } else if (match[3]) {
                named[match[3].toLowerCase()] = (typeof match[4] === 'string') ? match[4].replace(/&#8221;/g, '') : match[4];
            } else if (match[5]) {
                named[match[5].toLowerCase()] = (typeof match[6] === 'string') ? match[6].replace(/&#8221;/g, '') : match[6];
            } else if (match[7]) {
                numeric.push(match[7]);
            } else if (match[8]) {
                numeric.push(match[8]);
            }
        }

        return {
            named,
            numeric,
        };
    }),

    /**
     * Generate a ShortcodeParser Object from a RegExp match
     *
     * Accepts a `match` object from calling `regexp.exec()` on a `RegExp`
     * generated by `wp.shortcode.regexp()`. `match` can also be set to the
     * `arguments` from a callback passed to `regexp.replace()`.
     *
     * @param match
     * @return {ShortcodeParser}
     */
    fromMatch(match) {
        let type;

        if (match[4]) {
            type = 'self-closing';
        } else if (match[6]) {
            type = 'closed';
        } else {
            type = 'single';
        }

        return new wp.shortcode({
            tag: match[2],
            attrs: match[3],
            type,
            content: match[5],
        });
    },
};

/**
 * ShortcodeParser Objects
 *
 * ShortcodeParser objects are generated automatically when using the main
 * `wp.shortcode` methods: `next()`, `replace()`, and `string()`.
 *
 * To access a raw representation of a shortcode, pass an `options` object,
 * containing a `tag` string, a string or object of `attrs`, a string
 * indicating the `type` of the shortcode ('single', 'self-closing', or
 * 'closed'), and a `content` string.
 *
 * @class ShortcodeParser
 * @param options
 * @return {wp.shortcode}
 */
wp.shortcode = _.extend(function (options) {
    _.extend(this, _.pick(options || {}, 'tag', 'attrs', 'type', 'content'));

    const attrs = this.attrs;

    // Ensure we have a correctly formatted `attrs` object.
    this.attrs = {
        named: {},
        numeric: [],
    };

    if (!attrs) {
        return;
    }

    // Parse a string of attributes.
    if (_.isString(attrs)) {
        this.attrs = wp.shortcode.attrs(attrs);

        // Identify a correctly formatted `attrs` object.
    } else if (_.isEqual(_.keys(attrs), ['named', 'numeric'])) {
        this.attrs = attrs;

        // Handle a flat object of attributes.
    } else {
        _.each(options.attrs, function (value, key) {
            this.set(key, value);
        }, this);
    }
}, wp.shortcode);

_.extend(wp.shortcode.prototype, {
    /**
     * Get a shortcode attribute
     *
     * Automatically detects whether `attr` is named or numeric and routes
     * it accordingly.
     *
     * @param attr
     * @return {*}
     */
    get(attr) {
        let array = this.attrs.named;

        if (_.isNumber(attr)) {
            array = this.attrs.numeric;
        }

        if (attr in array) {
            return array[attr];
        }

        return null;
    },

    /**
     * Set a shortcode attribute
     *
     * Automatically detects whether `attr` is named or numeric and routes
     * it accordingly.
     *
     * @param attr
     * @param value
     * @return {wp.shortcode}
     */
    set(attr, value) {
        this.attrs[_.isNumber(attr) ? 'numeric' : 'named'][attr] = value;
        return this;
    },

    /**
     * Transform the shortcode match into a string
     *
     * @return {string}
     */
    string() {
        let text = `[${this.tag}`;

        _.each(this.attrs.numeric, (value) => {
            if (/\s/.test(value)) {
                text += ` "${value}"`;
            } else {
                text += ` ${value}`;
            }
        });

        _.each(this.attrs.named, (value, name) => {
            text += ` ${name}="${value}"`;
        });

        // If the tag is marked as `single` or `self-closing`, close the
        // tag and ignore any additional content.
        if (this.type === 'single') {
            return `${text}]`;
        } if (this.type === 'self-closing') {
            return `${text} /]`;
        }

        // Complete the opening tag.
        text += ']';

        if (this.content) {
            text += this.content;
        }

        // Add the closing tag.
        return `${text}[/${this.tag}]`;
    },

    /**
     * Returns an attribute/value pair string literal, primarily
     * for use in ContentParser.
     *
     * This addresses an issue where null items are passed as a
     * string of "null", since JSX requires objects and strings
     * to be notated correctly.
     *
     * This is a helper method to make the contents of ContentParser less verbose.
     *
     * isString is optional
     * prop is optional
     */
    getProp(attr, isString = true, prop) {
        const value = this.get(attr);

        // optionally override the returned attribute name for cases where
        // the shortcode attribute doesn't match the React/JSX prop name
        prop = prop || attr;

        // if value is null etc. return nothing
        if (!value) {
            return '';
        }

        // if string, format as such
        if (isString) {
            return `${prop}="${value}"`;
        }
        return `${prop}={${value}}`;
    },
    getValue(attr) {
        return this.get(attr);
    },
});

class ShortcodeParser {
    constructor(tags) {
        this.tags = [];
    }

    addTag(tag, callback) {
        this.tags.push({ tag, callback });
        return this;
    }

    replaceTag(tag, callback) {
        let found = false;

        this.tags.map((sc, i) => {
            if (sc.tag === tag) {
                this.tags[i] = { tag, callback };
                found = true;
            }
        });

        if (!found) this.addTag(tag, callback);

        return this;
    }

    parse(content) {
        this.tags.forEach((tag) => {
            content = wp.shortcode.replace(tag.tag, content, tag.callback);
        });

        return content;
    }
}

export default ShortcodeParser;
