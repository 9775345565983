import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const ListItem = styled.li`
    padding-left: 3.5em;
    margin-bottom: 1.75em;
    h3 {
        margin: 0;
        line-height: 1.75;
        font-size: 1.25em;
    }
    p {
        font-size: 1em;
        &:not(.expander__trigger) {
            margin-top: 0;
        }
    }
    &:before {
        position: absolute;
        margin-left: -3em;
        counter-increment: step;
        content: counter(step);
        width: 2em;
        height: 2em;
        line-height: 1.75;
        border: 2px solid #007ACE; // @primaryColor
        border-radius: 50%;
        text-align: center;
        font-size: 1.125em;
        color: #007ACE; // @primaryColor
    }
    > .header {
        font-weight: bold; // @bold
        margin: 0;
        line-height: 1.75;
    }
`;

export default class StepListItem extends React.PureComponent {
    static propTypes = {
        heading: PropTypes.string,
        children: PropTypes.node.isRequired,
    };

    static defaultProps = {
        heading: false,
    };

    render() {
        const {
            heading,
            children,
        } = this.props;
        return (
            <ListItem>
                { heading ? (
                    <div className="header">{ heading }</div>
                ) : null}
                { children }
            </ListItem>
        );
    }
}
