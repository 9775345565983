import React from 'react';

const LazyLoadImage = (props) => (
    <img
        className="ui centered image"
        alt={props.alt || ''}
        src={props.src}
    />
);

export default LazyLoadImage;
