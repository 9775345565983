import { Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React from 'react';

export default class ArticleHeader extends React.PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        categories: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
        })).isRequired,
        readTimeMinutes: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
    };

    render() {
        const {
            title,
            readTimeMinutes,
        } = this.props;

        return (
            <header style={{ clear: 'both' }}>
                <Header as="h1">{title}</Header>
                {/*<List divided horizontal size="small" className="categories">*/}
                {/*    {categories.map((category) => {*/}
                {/*        let link = `/learn/${category.slug}/`;*/}

                {/*        if (link === '/learn/learn/') {*/}
                {/*            link = '/learn/';*/}
                {/*        }*/}

                {/*        return (*/}
                {/*            <List.Item key={category.id}>*/}
                {/*                <a href={link}>{category.name}</a>*/}
                {/*            </List.Item>*/}
                {/*        );*/}
                {/*    })}*/}
                {/*</List>*/}
                <p>
                    Estimated read time:
                    {' '}
                    {readTimeMinutes}
                    {' '}
minutes
                </p>
            </header>
        );
    }
}
