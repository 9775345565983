import PropTypes from 'prop-types';
import styled from 'styled-components';
import React from 'react';

const FormulaWrapper = styled.div`
    text-align: center;
    margin: 2em 0;
    padding: 1em;
    font-size: 1.5em;
    background: #dedede;
    .formula {
        display: inline-block;
        background-color: #fff;
        position: relative;
        padding-bottom: 1em;
        &:after {
            bottom: 0px;
            background-position: left bottom;
            background: linear-gradient(-45deg,#dedede .5em,transparent 0),linear-gradient(45deg,#dedede .5em,#fff 0);
            content: ' ';
            display: block;
            position: absolute;
            left: 0px;
            width: 100%;
            height: 1em;
            background-repeat: repeat-x;
            background-size: 1em 1em;
        }
    }
    .formula__heading {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 14px;
        color: #fff;
        background: #4fa5e3;
        padding: 0.75em 0.5em;
    }
    .formula__list {
        padding: 0.25em 0.5em 0.25em 0.5em;
        list-style: none;
    }
    .formula__value {
        color: #4fa5e3;
    }
    .formula__operator {
        color: #aaa;
        position: relative;
        &.formula__operator--result {
            margin-top: 0.75em;
            padding-top: 0.5em;
            border-top: 0.125em solid #ccc;
            color: #5cb85c;
            + .formula__value {
                color: #5cb85c;
            }
        }
    }
`;


export default class Formula extends React.Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
    }

    render() {
        const { heading, children } = this.props;
        return (
            <FormulaWrapper>
                <div className="formula">
                    { heading ? (
                        <div className="formula__heading">{ heading }</div>
                    ) : ''}
                    <div className="formula__contents">
                        <ul className="formula__list">
                            { children }
                        </ul>
                    </div>
                </div>
            </FormulaWrapper>
        );
    }
}

Formula.propTypes = {
    children: PropTypes.node.isRequired,
    heading: PropTypes.string,
};

Formula.defaultProps = {
    heading: null,
};
