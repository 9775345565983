import { graphql } from 'gatsby';
import Moment from 'moment';
import Model from './Model';
import danImage from '../images/authors/daniel-wesley.jpg';
import teamImage from '../images/authors/team.png';

const AUTHORS = [
    {
        slug: 'default',
        link: '/about/',
        name: 'DebtConsolidation Team',
        bio: '',
        photo: '/assets/images/authors/team.png',
    },
    {
        slug: 'daniel-wesley',
        link: '/about/',
        name: 'DebtConsolidation Team',
        bio: '',
        photo: '/assets/images/authors/team.png',
    },
];

const DEFAULT_AUTHOR = {
    slug: 'default',
    link: '/about/',
    name: 'DebtConsolidation.com',
    bio: 'Debt Consolidation Without Any Upfront Fees',
    photo: { publicURL: '/assets/images/authors/default-author-photo.png' },
};

export default class WordPressPost extends Model {
    get ogImage() {
        return (
            this.featured_image
            && this.featured_image.sourceImage
            && this.featured_image.sourceImage.url
            && this.featured_image.sourceImage.url.replace(/\d+x\d+/gm, '1200x630')
        );
    }

    set authorship(value) {
        this._authorship = value;
    }

    set author(value) {
        this._author = value;
    }

    get author() {
        const defaultAuthorship = AUTHORS.find(author => author.slug === 'default');
        return AUTHORS.find(author => author.slug === this._authorship.slug) || defaultAuthorship;
    }

    /**
     * Set the "private" categories member
     * @param value {[]}
     */
    set categories(value) {
        this._categories = value;
    }

    /**
     * Conditionally get the categories for the current Post
     * @returns {[]}
     */
    get categories() {
        if (this.template === 'credit-card-category') {
            return [];
        }
        return this._categories;
    }

    getImageFluid(aspectRatio = 16 / 6) {
        const featuredImageHuge = {
            width: 1170,
            height: Math.round(1170 * aspectRatio ** -1),
            url: this.featured_image.sourceImage.url.replace(
                /\d+x\d+/gm,
                `1170x${Math.round(1170 * aspectRatio ** -1)}`
            ),
        };

        return {
            aspectRatio,
            presentationWidth: featuredImageHuge.width,
            presentationHeight: featuredImageHuge.height,
            sizes: `(max-width: ${featuredImageHuge.width}px) 100vw, ${featuredImageHuge.width}px`,
            src: featuredImageHuge.url,
            srcSet: `${this.featured_image.smallImage.url} ${this.featured_image.smallImage.width}w,
                ${this.featured_image.mediumImage.url} ${this.featured_image.mediumImage.width}w,
                ${this.featured_image.largeImage.url} ${this.featured_image.largeImage.width}w,
                ${featuredImageHuge.url} ${featuredImageHuge.width}w`,
        };
    }

    /**
     * An explicit check for `false` is necessary so we don't accidentally de-index all our
     * blog posts in the case that `undefined` is returned by an invalid GraphQL query
     * @returns {string|null}
     */
    get robots() {
        if (this.acf && this.acf.robots) {
            return this.acf.robots;
        }

        return 'index, follow';
    }

    get titleSeo() {
        return (this.acf && this.acf.seo_title) || this.title;
    }

    getImage(size, dimensions) {
        return this[`${size}_image`].url.replace(/\d+x\d+/gm, dimensions);
    }

    get readTimeMinutes() {
        return this.read_time_minutes || null;
    }

    getReadableDate(format = 'MMMM D, YYYY') {
        return this.date ? Moment(this.date).format(format) : null;
    }

    get readableDate() {
        return this.getReadableDate('MMMM D, YYYY');
    }
}

export const WordPressPostAuthorBasicFragment = graphql`
    fragment WordPressPostAuthorBasic on wordpress__POST {
        author {
            id
            name
            description
            link
            slug
        }
    }
`;
